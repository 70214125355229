import AuthService from '@/store/services/auth.services'
const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;
const DATA_USR = JSON.parse(localStorage.getItem(NAME_APP));

const state = {
    succes: false,
    userData: DATA_USR  ? DATA_USR : null,
}

const getters = {
    getUserData: (state) => state.userData,
}

const actions = {
    login({ commit }, data) {
        let prefix = 'auth/';
        return AuthService.login(data.item, `${prefix}${data.entity}`).then(
            response => {
                commit('SET_LOGIN', response.data);
                return Promise.resolve(response.status);
            },
            () => {
                return Promise.reject(false);
            }
        );
    },
}

const mutations = {
    SET_LOGIN(state, data) {
        state.dataUser = data;
        localStorage.setItem(NAME_APP, JSON.stringify(data));  
    },
    LOGOUT(state) {
        state.dataUser = null;
        localStorage.removeItem(NAME_APP);
    },
}

export const authentication = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
