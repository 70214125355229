import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';
import '@mdi/font/css/materialdesignicons.css'; 

Vue.use(Vuetify);

export default new Vuetify({ 
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
        locales: { es },
        current: 'es',
    },
    theme: {
        themes: {
            light: {
                primary: '#010546',
                secondary: '#8a6230',
                tertiary: '#f3eee5',
                gold: '#ba9656',
                blackeudania: '#1e1e1e',
                whatsapp: '#4caf50'
            },
        },
    },
});
