import Vue from 'vue'
import Vuex from 'vuex'

import { authentication } from '@/store/modules/auth.module'
import {resident} from '@/store/modules/resident.module'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authentication,
    resident
  }
})
