import RepositoryService from '@/store/services/repository.service'
const PREFIX = 'bcsecurity/';
const state = {
    visit: null
}
const getters = {
    getvisit: (state)=> state.visit
}
const actions = {
    getData({ commit }, entity) {
        console.log("verificar qr module")
        return RepositoryService.get(`${PREFIX}${entity}`).then(
            (rs) => {
                commit('SET_ITEM', { entity: entity, response: rs.data.data });
                return Promise.resolve(rs.status);
            },
            () => {
                commit('SET_ITEM', { entity: entity, response: null });
                return Promise.reject(false);
            }
        );
    },
}
const mutations = {
    SET_ITEM(state, data) {
        console.log("result")
        let val_entity = data.entity.toString().split("/");
        if (val_entity.length > 0) {
            switch (val_entity[0]) {
                case "scheduled_visit":
                    state.visit = data.response;
                    break;
            }
        }
    },
}

export const resident = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}