import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutView from '../views/layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LayoutView,
    children:[
      {
        path: '',
        meta: { title: "Dashboard", requiresAuth: true},
        name: 'Dashboard',
        component: () => import('../views/dashboard/index.vue'),
      },
      {
        path: 'visit/:resident/:uuid',
        props: true,
        meta: { title: "Visita programada", requiresAuth: true },
        name: 'Bases',
        component: () => import('../views/visitors/index.vue')
      },
     
    ]
  },
  {
    path: '/visit/:uuid',
    props: true,
    meta: { title: "Visita programada", requiresAuth: false },
    name: 'Visita programada',
    component: () => import('../views/visitors/index.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/auth/login.vue'),
    name: 'Sesión',
    meta: { title: 'Autenticacion', icon: 'mdiAccounts', requiresAuth: false}
  },
  {
    path: '/logout',
    component: () => import('@/views/auth/logout.vue'),
    name: 'Close',
    meta: { title: 'Cerrar', icon: 'mdiAccounts', requiresAuth: false}
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
