import Vue from 'vue'
import moment from "moment";
export default function setup() {
    Vue.filter("toCurrency", function (value) {
        if (!value) return 0.00;
        var formatter = new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
        });
        return formatter.format(value);
    });

    Vue.filter('formatDate', function (value) {
        if (value) {
            return moment(String(value)).format('DD/MMMM/YYYY').toUpperCase()
        }
    });

    Vue.filter('formatDateTime', function (value) {
        if (value) {
            return moment(String(value)).format('DD/MM/YYYY HH:mm')
        }
    });

    Vue.filter('formatOnlyTime', function (value) {
        if (value) {
            return moment(String(value)).format('HH:mm')
        }
    });

    Vue.filter('longFormatDate', function (value) {
        if (value) {
            return moment(String(value)).format('llll')
        }
    });

    Vue.filter('capitalize', function (value) {
        if (!value) return ''
        let lower = value.toString().toLowerCase()
        return value.charAt(0).toUpperCase() + lower.slice(1)
    })
}

