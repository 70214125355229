import store from '../store'

const setLoader = {
    show: () => {
        store.dispatch("appConfig/initLoader");
    },
    hide: ()=>{
        setTimeout(() => store.dispatch('appConfig/closeLoader'), 1200);
    }
}

export default { setLoader }