import axios from 'axios';
import store from "@/store";
import commons from '@/utils/commons'

const API_URL = process.env.VUE_APP_SERVICES_HOST;


const unaxios = axios.create();

unaxios.interceptors.request.use(function (config) {
    commons.setLoader.show()
    return config

}, function (err) {
    commons.setLoader.hide()
    return Promise.reject(err);
});
unaxios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            commons.setLoader.hide()
            return Promise.resolve(response);
        } else {
            commons.setLoader.hide()
            return Promise.reject(response);
        }
    },
    error => {
        console.log("error " + error.response.statusText);
        if (error && error.response && error.response.status) {
            switch (error.response.status) {
                case 400:
                    console.log("error 400 " + error.response.statusText);
                    break;
                case 401:
                    console.log("error 401 " + error.response.data.message);
                    store.dispatch("toasts/notify_error_login", "Code error 401 " + error.response.data.message);

                    break;
                case 403:
                    console.log("error 403 " + error.response.statusText);
                    break;
                case 404:
                    console.log("error 404 " + error.response.statusText);
                    break;
                case 500:
                    console.log("error 500 " + error.response.statusText);
                    break;
                case 502:
                    console.log("error 502 " + error.response.statusText);
            }
            commons.setLoader.hide()
            return Promise.reject(error.response);
        }
        else {
            commons.setLoader.hide()
        }
    }
);

class AuthService {
    async login(payload, entity) {
        return unaxios.post(`${API_URL}${entity}`, payload);
    }

    async me(payload, entity) {
        return axios.post(`${API_URL}${entity}`, {});
    }
}

export default new AuthService();